import  React from "react"
import Layout from "../../components/layout"
import { Link } from 'gatsby'
import Head from '../../components/head'
import LatQuotesIntro from './LatQuotesIntro'

// markup
const LatQuotes_h = () => {
  return (
   <Layout >
      <Head title='Latin Quotes and Expressions - H'/>
     <h1>LATIN QUOTES AND EXPRESSIONS</h1>
     <h2>H</h2>
     <LatQuotesIntro />
    
     <p><b>Habeas corpus</b> - You must have the body, i.e. You must justify an imprisonment</p>
<p><b>Habemus Papam</b> - We have a pope. (used at the announcement of a new pope)</p>
<p><b>Hac lege</b> - With this law</p>
<p><b>Haec olim meminisse ivvabit</b> - Time heals all things, i.e. Wounds, offenses</p>
<p><b>Hannibal ante portas!</b> - Hannibal is at the doors! The enemy/danger is at the doors!</p>
<p><b>Haud ignota loquor</b> - I say things that are known</p>
<p><b>Helluo librorum</b> - A glutton for books. (bookworm)</p>
<p><b>Hic et nunc</b> - Here and now</p>
<p><b>Hic habitat felicitas</b> - Here dwells happiness</p>
<p><b>Hic jacet (HJ)</b> - Here lies. (written on gravestones or tombs)</p>
<p><b>Hic jacet sepultus (HJS)</b> - Here lies buried</p>
<p><b>Hinc illae lacrimae</b> - Hence these tears. (Terence)</p>
<p><b>Historia est vitae magistra</b> - The history is the tutor of life</p>
<p><b>Hoc erat in votis</b> - This was among my prayers</p>
<p><b>Hoc est in votis</b> - This is in my prayers</p>
<p><b>Hoc est verum et nihili nisi verum</b> - This is the truth and nothing but the truth</p>
<p><b>Hoc est vivere bis vita posse priore frvi</b> - To live twice is to make useful profit from one's past. Experience is the best teacher, so learn from it</p>
<p><b>Hoc natura est insitum, ut quem timueris, hunc semper oderis</b> - It's an innate thing to always hate the one we've learnt to fear</p>
<p><b>Hoc tempore obsequium amicos, veritas odium parit</b> - In these days friends are won through flattery, the truth gives birth to hate. (Terence)</p>
<p><b>Hodie mihi, cras tibi</b> - Today for me, tomorrow for you</p>
<p><b>Homines libenter quod volunt credunt</b> - Men believe what they want to. (Terentius)</p>
<p><b>Homines, dum docent, discunt</b> - Men learn while they teach. (Seneca)</p>
<p><b>Homo doctus is se semper divitias habet</b> - A learned man always has wealth within himself</p>
<p><b>Homo homini lupus</b> - Man is a wolf to man</p>
<p><b>Homo praesumitur bonus donec probetur malus</b> - One is innocent until proven guilty</p>
<p><b>Homo proponit, sed Deus disponit</b> - Man proposes, but God disposes</p>
<p><b>Homo sum</b> - I am a man</p>
<p><b>Homo sum, humani nihil a me alienum puto</b> - I am human, therefore nothing human is strange to me</p>
<p><b>Homo vitae commodatus non donatus est</b> - Man has been lent to life, not given. (Pubilius Syrus)</p>
<p><b>Honor virutis preamium</b> - Honour is the reward of virtue</p>
<p><b>Honores mutant mores</b> - The honours change the customs. (Power corrupts)</p>
<p><b>Honoris causa (h.c.)</b> - As in doctorate, an honorary degree</p>
<p><b>Horas non numero nisi serenas</b> - I count only the bright hours. (Inscription on ancient sundials)</p>
<p><b>Horribile dictu</b> - Horrible to tell</p>
<p><b>Horror vacui</b> - Fear of empty places</p>
<p><b>Hostis humani generis</b> - Enemy of the human race</p>

   </Layout>
  )
}

export default LatQuotes_h
